<template>
  <div>
 <div ref="markdownContent" :v-html="rendered" @click="event => $emit('click', event)">
    <slot></slot>
 </div>
    <div ref="dynamic" ></div>
 </div>

 
</template>

<script>
import Vue from 'vue';
import MarkdownIt from 'markdown-it';     
import MarkdownItSCT from 'markdown-it-shortcode-tag';     
// see https://markdown-it.github.io/markdown-it/ for underlying engine
// see https://github.com/ravenq/markdown-it-vue/blob/master/src/markdown-it-vue.vue for full example
export default {
  name: "VueMarkdown",
  components: {
  },
  props: {
    html: { type: Boolean, default: false },
    source: { type: String, default: null },
  },
  data: function() {
    return {
      compiled: null,
      md: null,
      rendered: null,
    };
  },
  async mounted() {
      this.md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true
      });
      this.registerShortCodes(this.md);

      var markdown = this.$refs.markdownContent.innerHTML;
      if (markdown) {
        this.render(markdown);
      }
      else if (this.source) {
        this.render(this.source);
      }
  },
  methods: {
      registerShortCodes(md) {
        var self = this;
        var supported = ['img', 'video', 'iframe'];
        
        var shortcodes = {
            media: {
                render: function (attrs, env) {
                    if (supported.indexOf(attrs.method) < 0) {
                        throw new Error('unsupported medium');
                    }
                    var out = '<' + attrs.method;
                    out += ' src="' + (attrs.src || '') + '"';
                    out += ' alt="' + (attrs.alt || '') + '"';
                    if (attrs.width) out += ' width="' + attrs.width + '"';
                    if (attrs.side) out += ' style="float:' + attrs.side + '"';
                    return out + '>';
                }
            },
            block: {
              render: function (attrs, env) {
                var icon = attrs.icon || '';
                var title = attrs.title || '';
                var text = attrs.text || '';
                var href = attrs.href || '';
                var tag = href ? 'a' : 'div';

                return `<${tag} class="auto-block-grid pa-4 v-card ${href?'v-card--link':''} v-sheet theme--light elevation-2 grey lighten-4 flex d-flex flex-column" href="${href}">
                          <i class="${icon?'v-icon':'d-none'} notranslate fa ${icon} theme--light grey--text d-inline-block" style="font-size:50px;"></i>
                          <h3 class="my-4">${title}</h3>
                          <p class="d-flex">${text}</p>
                        </${tag}>`
              }
            },
            video: {
              render: function (attrs, env) {
                var id = attrs.id || '';

                //console.log('render, staticRenderFns',vue, self.$refs)
                //let { render, staticRenderFns } = Vue.compile(`<VideoEmbed videoId="${id}" />`);
                //var vue = new Vue({ el: self.$refs.dynamic, render, staticRenderFns, router: self.$router });
                return `<a class="text-center d-inline-block videoPlayer clickToPlayVideo" style="width:640px;height:360px; max-width:100%; position:relative;cursor:pointer;" data-video-id="${id}">
                          <i class="v-icon fa fa-play theme--light" style="font-size:100px;position:absolute;left:50%;top:50%;margin-top:-50px; margin-left:-50px;"></i>
                          <img src="https://img.youtube.com/vi/${id}/maxresdefault.jpg" width="640" height="360" style="object-fit:contain; max-width:100%;"/>
                        </a>`;
              }
            },
            button: {
              render: function (attrs, env) {
                //console.log('render button', attrs)
                var outlined = attrs.outlined;
                var color = attrs.color || 'primary';
                var block = attrs.block;
                var cssClass = outlined 
                               ? `v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--router theme--light v-size--default ${color}--text` 
                               : `v-btn v-btn--contained v-btn--router theme--light v-size--default ${color}`;

                return `<p><a href="${attrs.href}" class="${cssClass}"><span class="v-btn__content">${attrs.text}</span></a></p>`
              }
            },
            banner: {
              render: function (attrs, env) {
                //console.log('render button', attrs)
                var color = attrs.color || 'green';
                var title = attrs.title || '';
                var message = attrs.message || '';
                var primaryLabel = attrs.primaryLabel || '';
                var primaryHref = attrs.primaryHref || '';
                var secondaryLabel = attrs.secondaryLabel || '';
                var secondaryHref = attrs.secondaryHref || '';
                var primaryButton = primaryLabel && primaryHref ? `<a href="${primaryHref}" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default white"><span class="v-btn__content">${primaryLabel}</span></a>` : "";
                var secondaryButton = secondaryLabel && secondaryHref ? `<a href="${secondaryHref}" class="ml-4 v-btn v-btn--outlined theme--light v-size--default white--text"><span class="v-btn__content">${secondaryLabel}</span></a>` : "";

                return `<div class="${color} white--text my-10 py-6 py-md-12 mx-n4">
                          <div class="container text-center">
                            <div class="col-md-12 col-12">
                              <h2>${title}</h2>
                              <h1>${message}</h1>
                              <p class="${primaryButton || secondaryButton ? 'mb-0 mt-4' : 'd-none'}">
                                ${primaryButton} ${secondaryButton}
                              </p>
                            </div>
                          </div>
                        </div>`
              }
            }
        }

        md.use(MarkdownItSCT, shortcodes);
      },

      render(markdown) {
        if (!markdown) return;
        var rendered = this.md.render(markdown);
        //console.log(markdown, '-->', rendered);
        this.$refs.markdownContent.innerHTML = rendered;
      },
  },
  watch: {
      async source(val) {
        await this.$nextTick();
        this.render(val);
      }
  }
};
</script>

<style lang="scss">
</style>