<template>
  <div :class="`event-header ${dark? 'theme--dark v-toolbar v-sheet v-sheet--tile':''} ${logo ? 'event-header--logo' : ''}`">
    <v-img
      v-if="img"
      :src="img"
      :class="`white--text align-end ${logo ? 'logo-container': ''} ${(compact||mini) || $vuetify.breakpoint.xs?'container-xs':''} ${!(compact||mini) && $vuetify.breakpoint.sm?'container-sm':''}`"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      :height="mini ? '80px': (compact || $vuetify.breakpoint.xs?'150px':'300px')"
      width="auto"
    >
      <span v-if="logo" :class="`event-logo pa-3 v-card elevation-7 v-sheet theme--light`">
        <img alt="Logo" :src="logo" />
      </span>
      <v-card-title class="display-2" v-text="title"></v-card-title>
    </v-img>
    <v-card-title v-else class="display-2" v-text="title"></v-card-title>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  components: {
  },
  props: {
    img: String,
    logo: String,
    title: String,
    dark: Boolean,
    mini: Boolean,
    compact: Boolean,
  },
  mounted() {
  },
};
</script>

<style lang="scss">
  .event-header { position: relative; z-index: 4; }
  .logo-container { overflow: inherit !important; margin-bottom: 42px;}
  .logo-container .v-responsive__sizer { padding-bottom: 0!important;}
  .logo-container .v-responsive__content { width: auto !important; }
  .logo-container .display-2 { display: inline-block; margin-left: 250px; line-height: 1.2em !important;}
  .logo-container.container-xs .display-2 { margin-left: 150px; font-size: 1.5em !important;}
  .logo-container.container-sm .display-2 { margin-left: 200px; }
  .event-logo { background-color: white; display: inline-block !important; left: 16px; position: absolute !important; bottom: -32px; /*line-height: 200px;*/ }
  .event-logo img { max-width: 200px; }
  /*.container-xs .event-logo { line-height: 100px; }
  .container-sm .event-logo { line-height: 150px; }*/
  .container-xs .event-logo img { max-width: 100px; }
  .container-sm .event-logo img { max-width: 150px; }
</style>