import services from "./index";
import qs from "querystring";

export default {
    getMapTemplates() {
        return services.http.get(`/v1.0/assets/map-templates`);
    },
    getMapTemplate(id) {
        return services.http.get(`/v1.0/assets/map-templates/${id}`);
    },
    getWellbeingPacks() {
        return services.http.get(`/v1.0/assets/wellbeing-packs`);
    },
    getWellbeingPack(id) {
        return services.http.get(`/v1.0/assets/wellbeing-packs/${id}`);
    },
    // getEventTemplates() {
    //     return services.http.get(`/v1.0/assets/event-templates`);
    // },
    getEventTemplates(tags, query) {
        return services.http.get(`/v1.0/assets/event-templates?tags=${tags ? tags.join(',') : ''}&query=${query||''}`);
    },
    getEventTemplate(id) {
        return services.http.get(`/v1.0/assets/event-templates/${id}`);
    },
    validateEventTemplate(id, request) {
        return services.http.post(`/v1.0/assets/event-templates/${id}/validate`, request);
    },
    getTrainingPlans() {
        return services.http.get(`/v1.0/assets/training-plans`);
    },
    getTrainingPlan(id) {
        return services.http.get(`/v1.0/assets/training-plans/${id}`);
    },

}